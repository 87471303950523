// 指定された特定の年・月の記事一覧ページ
// ref: https://mtane0412.com/implementing-yearly-and-montly-archives-in-gatsby-and-contentful/
import React from "react";
import { jsx, Heading } from "theme-ui"
import { graphql, Link } from "gatsby";
import get from "lodash/get";
import Layout   from "../../node_modules/@lekoarts/gatsby-theme-minimal-blog/src/components/layout"
import SEO from "../../node_modules/@lekoarts/gatsby-theme-minimal-blog/src/components/seo"
// import ArchivePagination from "../components/archive-pagination" 不要で使ってないのでコメントアウト

class ArchiveTemplate extends React.Component {
  render() {
    const posts = get(this, "props.data.allMdxPost.edges");
    const totalCount = get(this, "props.data.allMdxPost.totalCount");
    const {thisYear, thisMonth} = this.props.pageContext;

    const px = [`32px`, `16px`, `8px`, `4px`]
    const shadow = px.map((v) => `rgba(0, 0, 0, 0.15) 0px ${v} ${v} 0px`)

    return (
      <Layout location={this.props.location}>
        <SEO
          title={thisMonth ? `${thisYear}年${thisMonth}月の記事` : `${thisYear}年の記事`}
          desc={thisMonth ? `${thisYear}年${thisMonth}月の記事` : `${thisYear}年の記事`}
          noindex
        />
        <Heading variant="styles.h2">{thisMonth ? `${thisYear}年${thisMonth}月の記事` : `${thisYear}年の記事`}</Heading>
        <section sx={{ my: 5, ".gatsby-resp-image-wrapper": { my: [4, 4, 5], boxShadow: shadow.join(`, `) } }}>
          <ul className="article-list">
            {posts.map(({ node }) => {
              return (
                <li key={node.slug}>
                  <Link to={node.slug}>{node.title}</Link>
                </li>
              );
            })}
          </ul>
        </section>
      </Layout>
    );
  }
}

export default ArchiveTemplate;

export const archivePageQuery = graphql`
  query BlogPostsByDate($periodStartDate: Date!, $periodEndDate: Date!) {
    allMdxPost(filter: {date: {gte: $periodStartDate, lte: $periodEndDate}}) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          date(formatString: "MMMM DD, YYYY")
          slug
          title
        }
      }
    }
}
`
